import { useGLTF } from '@react-three/drei';
import { applyProps } from '@react-three/fiber'
import { useEffect, useMemo } from 'react';
import { Vector3 } from 'three';

function CarPart(props) {
  const {
    modelFile,
    selectedModel
  } = props;
  const { scene } = useGLTF(modelFile, '/draco/gltf/')

  const modeScale = useMemo(() => {
    if (selectedModel === 3) {
      return [0.8, 0.8, 0.8]
    } else if (selectedModel === 4) {
      return [0.5, 0.5, 0.5]
    } else {
      return [1.8, 1.8, 1.8]
    }
  }, [selectedModel])

  useEffect(() => {
    scene.traverse((ch) => {
      if (ch.isMesh) {
        applyProps(ch, { castShadow: true });
        applyProps(ch, { position: new Vector3(0,0,0) });
      }
    })
  }, [scene])

  return (
    <group position={[0,0.05,0]} scale={modeScale}>
      <primitive object={scene} />
    </group>
  );
}

export default CarPart;
