import { useRef, useMemo, useEffect, useState, Suspense } from 'react';
import { Canvas } from '@react-three/fiber'
import {
  CameraControls,
  PerspectiveCamera,
  Environment,
  useProgress,
} from '@react-three/drei';
import { Triangle } from 'react-loader-spinner'
import { debounce } from 'lodash';

import hdr from './assets/canary_wharf_2k_fixed-red.hdr';

import Metal1 from './assets/metal/87593709-F.glb';
import Metal2 from './assets/metal/87609904-Q.glb';
import Metal3 from './assets/metal/87635541-L.glb';
import Metal4 from './assets/metal/L4161019-A.glb';

import CarPart from './CarPart';
import './index.css';

function LoadingScreen(props) {
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    if (props.loaded) {
      setTimeout(() => {
        setIsHidden(true);
      }, 1000);
    }
  }, [props.loaded])
  return <div
    className={
      "w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 filter backdrop-blur transition-opacity delay-500 duration-500 z-40"
      + (props.loaded ? ' opacity-0' : ' opacity-100')
      + (isHidden ? ' invisible' : ' ')
    }
  >
    <Triangle
      height="80"
      width="80"
      color="#002355"
      ariaLabel="triangle-loading"
      wrapperStyle={{}}
      wrapperClass="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      visible={true}
    />
  </div>;
}

function App() {
  const camRef = useRef();
  const cameraControlsRef = useRef();

  const startDistance = useRef(0);
  const distanceCount = useRef(0);
  const endDistance = useRef(0);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);

  const [showContact, setShowContact] = useState(false);

  const [showLogo, setShowLogo] = useState(false);

  const [selectedModel, setSelectedModel] = useState(1);

  const { progress, loaded } = useProgress();

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (progress === 100 && loaded > 0) {
      setTimeout(() => {
        setShowLogo(true)
      }, 2000);
    } else {
      setShowLogo(false)
    }
  }, [progress, loaded])

  useEffect(() => {
    if (!cameraControlsRef.current) {
      return;
    }
    setTimeout(() => {
      if (selectedModel === 2) {
        cameraControlsRef.current.setPosition(-1.0774443844849133, 1.3406724999847257, 2.3296302930505046, true);
      } else {
        cameraControlsRef.current.setPosition(-1.4780651446558473, 0.8397318951435856, 2.171515730546785, true);
      }
      cameraControlsRef.current.saveState();
      cameraControlsRef.current.setTarget(0, 0, 0, true);
    }, 200);
  }, [selectedModel])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function onCameraUpdate(e) {
    if (cameraControlsRef.current) {
      if (distanceCount.current > 0.9) {
        startDistance.current = e.target.distance;
        distanceCount.current = 0;
      } else {
        distanceCount.current += 0.01;
      }
      handleOnZoom(e.target.distance);
    }
  }

  const handleOnZoom = (distance) => {
    if (distance > startDistance.current) {
      endDistance.current = startDistance.current;
    }
    if (endDistance.current !== 0 && (distance > endDistance.current && distance - endDistance.current > 0.5)) {
      setZoomOut();
    }
  };

  const setZoomOut = debounce(function() {
    endDistance.current = 0;
    cameraControlsRef.current.dolly(-5, true);
    cameraControlsRef.current.setTarget(0, 0, 0, true);
  }, 500);

  return (
    <div
      style={{
        height: 'var(--vh)'
      }}
      className="relative"
    >
      <LoadingScreen loaded={progress >= 100} />
      <div
        className={
          "absolute w-full left-1/2 bottom-4 transform -translate-x-1/2 flex justify-center items-center z-20"
        }
      >
        <div className="flex gap-2 md:gap-5">
          <button
            className={
              "relative z-10 p-2 lg:px-4 lg:py-2 min-w-[65px] border-2 bg-white shadow rounded-lg flex items-center justify-center font-bold text-[10px] lg:text-lg hover:text-[#141414]"
              + (selectedModel === 1 ? " text-[#002355] border-black" : " text-[#aaaaaa] border-transparent")
            }
            onClick={() => setSelectedModel(1)}
          >
            1000073215
            <br />
            SCHLIESSPLATTE
          </button>
          <button
            className={
              "relative z-10 p-2 lg:px-4 lg:py-2 min-w-[65px] border-2 bg-white shadow rounded-lg flex items-center justify-center font-bold text-[10px] lg:text-lg hover:text-[#141414]"
              + (selectedModel === 2 ? " text-[#002355] border-black" : " text-[#aaaaaa] border-transparent")
            }
            onClick={() => setSelectedModel(2)}
          >
            1000075728
            <br />
            FUEHRUNG
          </button>
          <button
            className={
              "relative z-10 p-2 lg:px-4 lg:py-2 min-w-[65px] border-2 bg-white shadow rounded-lg flex items-center justify-center font-bold text-[10px] lg:text-lg hover:text-[#141414]"
              + (selectedModel === 3 ? " text-[#002355] border-black" : " text-[#aaaaaa] border-transparent")
            }
            onClick={() => setSelectedModel(3)}
          >
            1000078664
            <br/>
            BANDROLLE
          </button>
          <button
            className={
              "relative z-10 p-2 lg:px-4 lg:py-2 min-w-[65px] border-2 bg-white shadow rounded-lg flex items-center justify-center font-bold text-[10px] lg:text-lg hover:text-[#141414]"
              + (selectedModel === 4 ? " text-[#002355] border-black" : " text-[#aaaaaa] border-transparent")
            }
            onClick={() => setSelectedModel(4)}
          >
            1000249660
            <br/>
            TRANSPORTRING
          </button>
        </div>
      </div>
      <Suspense fallback={<LoadingScreen />}>
        <Canvas
          gl={{
            antialias: true,
            pixelRatio: Math.min(window.devicePixelRatio, 2),
          }}
          id="three-canvas"
          className="w-full h-full"
        >
          {
            selectedModel === 1
              ? <CarPart
                modelFile={Metal1}
                selectedModel={selectedModel}
              />
              : <></>
          }
          {
            selectedModel === 2
              ? <CarPart
                modelFile={Metal2}
                selectedModel={selectedModel}
              />
              : <></>
          }
          {
            selectedModel === 3
              ? <CarPart
                modelFile={Metal3}
                selectedModel={selectedModel}
              />
              : <></>
          }
          {
            selectedModel === 4
              ? <CarPart
                modelFile={Metal4}
                selectedModel={selectedModel}
              />
              : <></>
          }
          <hemisphereLight intensity={0.5} />
          <PerspectiveCamera
            ref={camRef}
            fov={isMobileAndTabletControlVertical ? 45 : 30}
            position={selectedModel === 2 ? [-1.0774443844849133, 1.3406724999847257, 2.3296302930505046] : [-1.4780651446558473, 0.8397318951435856, 2.171515730546785]}
            makeDefault
          />
          <Environment
              background={false} // Whether to affect scene.background
              files={hdr}
            />
          <CameraControls
            ref={cameraControlsRef}
            minDistance={isMobileAndTabletControlVertical ? 1 : 1.1}
            maxDistance={isMobileAndTabletControlVertical ? 2.2 : 2.7}
            dollyToCursor={true}
            dollySpeed={0.5}
            smoothTime={0.09}
            draggingSmoothTime ={0.15}
            truckSpeed={0}
            onChange={onCameraUpdate}
          />
        </Canvas>
      </Suspense>
    </div>
  );
}

export default App;
